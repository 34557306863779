@import '../../variables';
@media screen and (min-width: 768px) {
    #home-app-container{
        background: rgb(36,37,116);
        background: linear-gradient(90deg, rgba(36,37,116,1) 0%, rgba(22,46,164,1) 74%, rgba(9,50,187,1) 100%);
        #menu-nav{
            position: fixed;
            z-index: 100;
            top: 0;
            left: 0;
            padding-right: 0;
            padding-left: 0;
            #first-header{
                background-color: $primary;
                padding-top: 3px;
                padding-bottom: 3px;
                justify-content: flex-end;
                #header-sing-container{
                    padding-right: 50px;
                    border-right: 1px solid $text-white;
                    .header-sing-opt, span{
                        margin: 0;
                        padding: 0;
                        cursor: pointer;
                        transition: .3s;
                        color: $text-white;
                        text-decoration: none;
                        border-bottom: 1px solid $primary;
                        &:hover{
                            border-bottom: 1px solid $text-white;
                        }
                    }
                }
                #header-social-media-list{
                    padding: 0;
                    margin:0;
                    list-style: none;
                    .header-social-media-item{
                        margin: 0 0 0 30px;
                        padding: 0;
                        height: 70%;
                        a{
                            color: $text-white;
                            height: 70%;
                            i{
                                font-size: 1rem;
                                transition: .3s;
                                &:hover{
                                    transform: scale(1.2) !important;
                                    color: $text-white !important;
                                }
                            }
                        }
                    }
                }
            }
            #secondary-header{
                background-color: rgba($color: #00057B, $alpha: 1);
                #header-business-logo{
                    padding: 0;
                    margin: 0;
                    width: 130px;
                    cursor: pointer;
                }
                #header-navigation-list{
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    width: inherit;
                    list-style: none;
                    .header-navigation-list-item{
                        margin: 0;
                        padding: 0;
                        a{
                            color: $text-white;
                            text-decoration: none;
                            transition: .3s;
                            font-weight: 400;
                            &:hover{
                                color: $text-white;
                                text-decoration: none;
                            }
                        }
                        &.header-navigation-normal{
                            margin: 0 7% 0 0;
                            
                        }
                        transition: .3s;
                        &:hover{
                            transform: scale(1.05);
                        }
                    }
                    #header-service-opt{
                        &:hover{
                            #header-services-opt-list{
                                display: block;
                            }
                        }
                        #header-services-opt-list{
                            transition: .3s;
                            margin: 0;
                            padding: 0 15px;
                            display: none;
                            width: 250px;
                            list-style: none;
                            text-align: center;
                            background-color: #E5E9F6;
                            position: absolute;
                            transform: translateX(-38%);
                            .header-services-opt-list-item{
                                padding: 3px 0;
                                color: $text-black;
                                cursor: pointer;
                                &:not(:last-child) {
                                    border-bottom: 1px solid $text-black;
                                }
                                a{
                                    color: $text-black;
                                    cursor: pointer;
                                    text-decoration: none;
                                    margin: 0;
                                    padding: 0;
                                    display: block;
                                    width: 100%;
                                }
                            }
                        }
                    }
                    
                    .header-pay-button{
                        background-color: #F6AA1C;
                        padding: 8px 50px;
                        border-radius: 54px;
                    }
                }
            }
        }
        #contact{
            margin: 0;
            padding: 10vh 0;
            display: block;
            width: 100%;
            background-image: url('/images/landing/contact/contact-bg.webp');
            background-size: cover;
            #contact-sub-container{
                padding: 0 5vw;
                #contact-message-container{
                    text-align: left;
                    color: $text-white;
                    margin: 0;
                    padding: 0 0 4vh 0;
                    width: 50%;
                    #contact-message-title{
                        font-size: 3.5rem;
                        font-weight: bold;
                        margin: 0 0 3vh 0;
                        padding: 0;
                    }
                    #contact-message-description{
                        font-size: 1.1rem;
                        font-weight: 400;
                        margin: 0 0 4vh 0;
                        padding: 0;
                        width: 95%;
                    }
                    #contact-buttons-container{
                        margin: 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        #contact-form-wp-btn{
                            border: 3px solid #FFFFFF;
                            border-radius: 32px;
                            background-color: rgba(255, 255, 255, .5);
                            color: $text-white;
                            padding: 6px 0;
                            width: 265px;
                            font-size: 1em;
                            font-weight: 600;
                            margin: 0 auto;
                            display: inline-block;
                            vertical-align: middle;
                            text-decoration: none;
                            transition: .3s;
                            max-width: 40%;
                            text-align: center;
                            i {
                                margin-right: 4px;
                                font-size: 1.2em;
                            }
                            &:hover{
                                background-color: #FFF;
                                color: $primary;
                            }
                        }
                        span{
                            display: inline-block;
                            vertical-align: middle;
                            margin: 0 20px;
                        }
                        #contact-form-btn-message{
                            font-size: 1.2rem;
                            margin: 0 auto;
                            display: inline-block;
                            vertical-align: middle;
                            text-decoration: none;
                            transition: .3s;
                            max-width: 40%;
                            i{
                                margin-left: 20px;
                                font-size: 1.6rem;
                            }
                        }
            
                    }
                }
                #contact-form-container{
                    background-color: rgba(255, 255, 255, .4);
                    border-radius: 20px;
                    width: 45%;
                    padding: 5vh 3vw;
                    .contact-form-input{
                        background-color: rgba(255, 255, 255, .9);
                        color: $text-black;
                        margin: 0 0 2vh 0;
                        padding: 15px 20px;
                        display: block;
                        width: 100%;
                        border: none;
                        border-radius: 6px;
                    }
                    #contact-form-btn{
                        background-color: rgba($color: #ffffff, $alpha: 0.95);
                        border-radius: 32px;
                        display: block;
                        margin: 1vh auto 0 auto;
                        padding: 5px 85px;
                        border: none;
                        font-size: 1.1em;
                        font-weight: 500;
                        color: $text-black;
                    }
                }
            }
        }
        #blog-preview-section{
            margin: 0;
            padding: 6vh 0;
            display: block;
            width: 100%;
            background-color: #FFF;
            .blog-title{
                margin: 0 0 5vh 0;
                padding: 0;
                display: block;
                width: 100%;
                text-align: center;
                color: $primary;
                font-weight: 600;
                font-size: 1.6rem;
            }
            .blog-list{
                margin: 2vh 0 0 0;
                display: flex;
                width: 100%;
                justify-content: space-between;
                .blog-item{
                    margin: 0;
                    padding: 20px 20px 25px;
                    display: block;
                    width: 24%;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    .blog-item-image{
                        width: 100%;
                        height: 20vw;
                        object-fit: cover;
                    }
                    .blog-item-text{
                        margin: 1vh 0 0 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        .blog-item-text-title{
                            margin: 0;
                            padding: 0;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            text-align: center;
                            color: $primary;
                            font-size: 1.1rem;
                            font-weight: 600;
                            -webkit-line-clamp: 3;
                            overflow-y: hidden;
                            text-overflow: ellipsis;
                            width: 100%;
                            height: 10vh;
                        }
                        .blog-item-text-link{
                            margin: 1vh auto 0 auto;
                            padding: 3px 0;
                            display: block;
                            width: 70%;
                            text-decoration: none;
                            text-align: center;
                            border: 2px solid $primary;
                            border-radius: 27px;
                            font-weight: 700;
                            cursor: pointer;
                            transition: .3s;
                            color: $primary;
                            &:hover{
                                background-color: $primary;
                                color: #FFF;
                            }
                        }
                    }
                }
            }
            .blog-see-more{
                margin: 5vh auto 0 auto;
                padding: 6px 0;
                display: block;
                width: 200px;
                text-align: center;
                background-color: $primary;
                color: #FFF;
                border-radius: 27px;
                text-decoration: none;
                
            }
        }
        #footer-container{
            background-image: url('/images/footer-background.webp');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            #footer-logo{
                margin: 0;
                padding: 7vh 0;
                display: block;
                width: 100%;
                width: 12%;
                cursor: pointer;
            }
            #footer-content{
                margin: 0;
                padding: 0;
                width: 100%;
                .footer-col{
                    padding: 0 35px;
                    margin: 0;
                    .footer-title,
                    .footer-link{
                        color: $text-white;
                        margin: 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        text-decoration: none;
                        cursor: pointer;
                        transition: .3s;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                    .footer-title{
                        font-weight: 500;
                        font-size: 1em;
                    }
                    .footer-link{
                        font-size: 0.9rem;
                        font-weight: 300;
                        margin-bottom: 7px;
                    }	
                    #footer-social-media-list{
                        margin: 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        text-align: center;
                        list-style: none;
                        .footer-social-media-item{
                            margin: 0;
                            padding: 0 20% 0 0;
                            a{
                                margin: 0;
                                padding: 0;
                                display: block;
                                width: 100%;
                                i{
                                    color: $text-white;
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #integrations{
        background-color: #F2F2F2;
        padding: 6vh 0;
        width: 100%;
        #integrations-title{
            color: $primary;
            text-align: center;
            margin: 0;
            padding: 2vh 0 4vh 0;
            font-weight: 600;
            font-size: 1.6rem;
            letter-spacing: 0.6px;
        }
        #integrations-list{
            /*makes an horizontal carousel list*/
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            text-align: center;
            /*change style of horizontal scrollbar*/
            &::-webkit-scrollbar {
                height: 5px;
            }
            .integration-item{
                display: inline-block;
                margin: 0 1%;
                text-align: center;
                .integration-item-image{
                    width: auto;
                    height: 100px;
                    transition: .3s;
                    &:hover{
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    #our-clients{
        background-color: #FFF;
        padding: 6vh 0;
        width: 100%;
        #our-clients-title{
            color: $primary;
            text-align: center;
            margin: 0;
            padding: 2vh 0 4vh 0;
            font-weight: 600;
            font-size: 1.6rem;
            letter-spacing: 0.6px;
        }
        .our-clients-list{
            /*makes an horizontal carousel list*/
            margin: 2vh 0;
            padding: 0;
            display: block;
            width: 100%;
            overflow-x: hidden;
            overflow-y: hidden;
            text-align: center;
            /*change style of horizontal scrollbar*/
            &::-webkit-scrollbar {
                height: 1px;
            }
            .our-clients-item{
                display: inline-block;
                margin: 2vh 2%;
                text-align: center;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-size: 100% auto; /* 100% width, auto height */
                background-repeat: no-repeat;
                background-position: center;
                transition: .3s;
                -webkit-box-shadow: 0px 5px 10px 0px rgba(204,204,204,1);
                -moz-box-shadow: 0px 5px 10px 0px rgba(204,204,204,1);
                box-shadow: 0px 5px 10px 0px rgba(204,204,204,1);
                /*set filter for background image bw*/
                filter: grayscale(100%);
                &:hover{
                    /*set filter for background image color*/
                    filter: grayscale(0%);
                    transform: scale(1.1);
                }
            }
        }
    }
    #testimonials{
        background-color: #F8F8F8;
        padding: 6vh 0;
        width: 100%;
        #testimonials-title{
            color: $primary;
            text-align: center;
            margin: 0;
            padding: 1vh 0 6vh 0;
            font-weight: 600;
            font-size: 1.6rem;
            letter-spacing: 0.6px;
        }
        .testimonials-container{
            margin: 0 0 4vh 0;
            padding: 0;
            display: block;
            width: 100%;
            .testimonial-item{
                .testimonial-item-image{
                    border: 5px solid #FFFFFF;
                    border-radius: 50%;
                    width: 100px;
                    margin-right: -50px;
                    z-index: 1;
                }
                .testimonial-item-text{
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 19px;
                    min-height: 28vh;
                    width: 22vw;
                    padding: 3vh 20px 3vh 60px;
                    .testimonial-item-text-description{
                        margin: 0;
                        padding: 0;
                        display: block;
                        color: $text-black;
                        font-weight: 400;
                        font-size: .8rem;
                        line-height: 20px;
                    }
                    .testimonial-item-text-author{
                        margin: 1vh 0 0 0;
                        padding: 0;
                        display: block;
                        color: $text-gray;
                        text-align: right;
                        font-weight: 300;
                        font-size: .8rem;
                    }
                    .testimonial-item-text-company{
                        margin: 0;
                        padding: 0;
                        display: block;
                        color: $text-gray;
                        text-align: right;
                        font-weight: 300;
                        font-size: .8rem;
                    }
                }
            }
        }
    }
    #success-stories{
        background-color: #F8F8F8;
        padding: 2vh 0 8vh 0;
        width: 100%;
        #success-stories-title{
            color: $primary;
            text-align: center;
            margin: 0;
            padding: 1vh 0 6vh 0;
            font-weight: 600;
            font-size: 1.6rem;
            letter-spacing: 0.6px;
        }
        #success-stories-list{
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;
            list-style: none;
            /*horizontal scrollable list*/
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            text-align: center;
            /*change style of horizontal scrollbar*/
            &::-webkit-scrollbar {
                height: 5px;
                background: #FFF;
    
            }
            &::-webkit-scrollbar-thumb{
                background: $text-gray;
            }
            &::-webkit-scrollbar-track{
                background: #F8F8F8;
            }
            .success-story-item{
                display: inline-block;
                margin: 0 5px;
                padding: 0 0 4vh 0;
                text-align: center;
                transition: .3s;
                width: 20vw;
                .success-story-item-image-container{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 20.2vw;
                    height: 26vw;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
    
                }
                .success-story-item-text-title{
                    margin: 2vh 0 0 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    color: $text-black;
                    font-size: 1.2em;
                    font-weight: 400;
                }
                .success-story-item-text-link{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    color: $text-link;
                    font-weight: 300;
                    font-size: 0.9em;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden; /* Hide the overflow */
                }
            }
        }
    }
    #chat-container{
        #chat-button{
            margin: 0;
            padding: 0;
            display: block;
            width: 75px;
            height: 75px;
            cursor: pointer;
            position: fixed;
            bottom: 3%;
            right: 3%;
            background-color: transparent;
            border-radius: 50%;
            transition: .3s;
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            background-image: url('/images/chat.svg');
            background-size: contain; /* 100% width, auto height */
            background-repeat: no-repeat;
            background-position: center;
            &:hover{
                transform: scale(1.1);
            }
        }
        #chat-conversation-container{
            margin: 0;
            padding: 0;
            display: none;
            width: 322px;
            position: fixed;
            bottom: 0;
            right: 3%;
            background-color: #FFF;
            z-index: 10;
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
            #chat-header{
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                background: rgb(0,5,123);
                background: linear-gradient(180deg, rgba(0,5,123,1) 0%, rgba(1,83,255,1) 100%);
                color: $text-white;
                margin: 0;
                padding: 10px 3% 10px 5%;
                width: 100%;
                .fa-comment-dots{
                    font-size: 1.5em;
                    &:hover{
                        color: #fff !important;
                    }
                }
                #chat-title{
                    margin: 0 0 0 10px;
                    padding: 0;
                    font-size: 1.1em;
                    font-weight: 600;
                }
                .fa-times{
                    &:hover{
                        color: #fff;
                    }
                }
            }
            #chat-body{
                margin: 0;
                padding: 0;
                display: block;
                width: 100%;
                #chat-messages-container{
                    height: 40vh;
                    margin: 0;
                    padding: 0 3%;
                    .chat-message-container{
                        padding: 0;
                        display: block;
                        width: 80%;
                        height: auto; /* Ajusta automáticamente la altura según el contenido */
                        overflow: hidden; 
                        .chat-message-robot-img{
                            width: 35px;
                        }
                        .chat-message-robot-text{
                            font-size: 0.9em;
                            padding: 10px 10px;
                            color: $text-black;
                            border-radius: 9px;
                            margin: 0;
                            width: 100%;
                            white-space: normal;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        &.robot{
                            margin: 10px auto 10px 0;
                            .chat-message-robot-text{
                                background-color: #E5EDFF;
                                margin-left: 10px;
                            }
                        }
                        &.user{
                            margin: 10px 0 10px auto;
                            .chat-message-robot-text{
                                background-color: #F0F0F0;
                            }
                        }
                    }
                }
                #chat-input-container{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    -webkit-box-shadow: 0px 0px 3px 0px rgba(153,153,153,1);
                    -moz-box-shadow: 0px 0px 3px 0px rgba(153,153,153,1);
                    box-shadow: 0px 0px 3px 0px rgba(153,153,153,1);
                    #chat-input{
                        width: inherit;
                        padding: 5px 0 0 3%;
                        height: 40px;
                        border: none;
                        &:focus, &:active  {
                            border-color: initial !important; /* Resets the border color to the default */
                            box-shadow: none !important; /* Removes the box shadow */
                            border: none !important;
                            outline: 0 !important;
                        }
                    }
                    #chat-send{
                        margin: 0 3%;
                        font-size: 15px;
                        
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    #home-app-container{
        background: rgb(36,37,116);
        background: linear-gradient(90deg, rgba(36,37,116,1) 0%, rgba(22,46,164,1) 74%, rgba(9,50,187,1) 100%);
        #menu-nav{
            background-color: $primary;
            position: fixed;
            z-index: 100;
            top: 0;
            left: 0;
            /*#first-header{
                padding-top: 10px;
                padding-bottom: 10px;
                justify-content: center;
                #header-sing-container{
                    .header-sing-opt, span{
                        margin: 0;
                        padding: 0;
                        cursor: pointer;
                        transition: .3s;
                        color: $text-white;
                        text-decoration: none;
                        border-bottom: 1px solid $primary;
                        &:hover{
                            border-bottom: 1px solid $text-white;
                        }
                    }
                }
                #header-social-media-list{}
            }*/
            #secondary-header{
                #burger-menu{
                    color: $text-white;
                    font-size: 1.5rem;
                    cursor: pointer;
                }
                #header-business-logo{
                    padding: 0;
                    margin: 0;
                    width: 100px;
                }
                #header-navigation-list{
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    width: inherit;
                    list-style: none;
                    .header-navigation-list-item{
                        margin: 0;
                        padding: 0;
                        a{
                            color: $text-white;
                            text-decoration: none;
                            transition: .3s;
                            &:hover{
                                color: $text-white;
                                text-decoration: none;
                            }
                        }
                        &.header-navigation-normal{
                            margin: 0 7% 0 0;
                            
                        }
                        transition: .3s;
                        &:hover{
                            transform: scale(1.05);
                        }
                    }
                    #header-pay-button{
                        background-color: #F6AA1C;
                        padding: 8px 50px;
                        border-radius: 54px;
                    }
                }
                #user-responsive-menu{
                    color: $text-white;
                    font-size: 1.5rem;
                    cursor: pointer;
                }
                
            }
            #responsive-menu{
                margin: 0;
                padding: 0 10px;
                width: 70%;
                height: 100vh;
                background-color: rgba(255, 255, 255, 1);
                position: fixed;
                display: none;
                top: 0;
                left: -100%;
                z-index: 100;
                -webkit-box-shadow: 0px 0px 5px 0px rgba(153,153,153,1);
                -moz-box-shadow: 0px 0px 5px 0px rgba(153,153,153,1);
                box-shadow: 0px 0px 5px 0px rgba(153,153,153,1);
                .header-pay-button{
                    background-color: #F6AA1C;
                    padding: 8px 50px;
                    border-radius: 54px;
                    color: $text-white;
                    text-decoration: none;
                    transition: .3s;
                }
                #responsive-close-opt{
                    margin: 0;
                    padding: 0;
                    display: block;
                    background-color: transparent;
                    cursor: pointer;
                    position: absolute;
                    top: 1%;
                    right: 3%;
                    border: none;
                    font-size: 1.5em;
                }
                #responsive-header-logo{
                    margin: 4% auto 0 auto;
                    display: block;
                    width: 60%;
                }
                #responsive-ul-nav{
                    margin: 4vh 0 0 0;
                    padding: 0;
                    display: block;
                    list-style: none;
                    text-align: center;
                    height: 80vh;
                    .nav-list{
                        margin: 0 0 2vh 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        font-size: 1.3em;
                        font-weight: 400;
                        cursor: pointer;
                        color: $primary;
                        a{
                            text-decoration: none;
                        }
                        &:hover{
                            color: $secondary;
                        }
                        span{
                            font-size: 18px;
                        }
                        .emergent-data{
                            margin: 0;
                            padding: 0;
                            display: block;
                            width: 100%;
                            .emergent-list{
                                margin: 0;
                                padding: 0;
                                display: block;
                                width: 100%;
                                list-style: none;
                                .emergent-list-category{
                                    text-decoration: none;
                                    font-size: 14px;
                                    margin: 0;
                                    padding: 0;
                                    display: block;
                                    width: 100%;
                                }
                                .emergent-list-item{
                                    margin: 0;
                                    padding: 0;
                                    display: block;
                                    width: 100%;
                                    .emergent-list-sub-category{
                                        margin: 0;
                                        padding: 0;
                                        display: block;
                                        width: 100%;
                                        text-decoration: none;
                                        font-size: 12px;
                                    }
                                    
                                }
                            }
                            
                        }
                    }
                }
            }
        }
        #contact{
            margin: 0;
            padding: 8vh 0;
            display: block;
            width: 100%;
            background-image: url('/images/landing/contact/contact-bg.webp');
            #contact-sub-container{
                flex-direction: column;
                #contact-message-container{
                    text-align: center;
                    color: $text-white;
                    margin: 0;
                    padding: 0 0 4vh 0;
                    width: 100%;
                    #contact-message-title{
                        font-size: 3rem;
                        font-weight: bold;
                        margin: 0 0 3vh 0;
                        padding: 0;
                    }
                    #contact-message-description{
                        font-size: 1.05rem;
                        font-weight: 400;
                        margin: 0 0 4vh 0;
                        padding: 0;
                        width: 100%;
                    }
                    #contact-buttons-container{
                        margin: 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        #contact-form-wp-btn{
                            margin: 0 auto;
                            display: inline-block;
                            vertical-align: middle;
                            text-decoration: none;
                            transition: .3s;
                            border: 3px solid #FFFFFF;
                            border-radius: 32px;
                            background-color: rgba(255, 255, 255, .5);
                            color: $text-white;
                            padding: 6px 25px;
                            width: 265px;
                            font-size: 1em;
                            font-weight: 600;
                            margin: 0 auto;
                            i {
                                margin-right: 4px;
                                font-size: 1.2em;
                            }
                            &:hover{
                                background-color: #FFF;
                                color: $primary;
                            }
                        }
                        span{
                            display: none;
                        }
                        #contact-form-btn-message{
                            display: none;
                        }
            
                    }
                }
                #contact-form-container{
                    background-color: rgba(255, 255, 255, .4);
                    border-radius: 20px;
                    width: 100%;
                    padding: 5vh 5vw;
                    .contact-form-input{
                        background-color: rgba(255, 255, 255, 0.9);
                        color: #272727;
                        margin: 0 0 2vh 0;
                        padding: 7px 20px;
                        display: block;
                        width: 100%;
                        border: none;
                        border-radius: 6px;
                    }
                    #contact-form-btn{
                        background-color: rgba(255, 255, 255, 0.95);
                        border-radius: 32px;
                        display: block;
                        margin: 1vh auto 0 auto;
                        padding: 5px 85px;
                        border: none;
                        font-size: 1.1em;
                        font-weight: 500;
                        color: #272727;
                    }
                }
            }
            
        }
        #blog-preview-section{
            margin: 0;
            padding: 6vh 0;
            display: block;
            width: 100%;
            background-color: #FFF;
            .blog-title{
                margin: 0 0 3vh 0;
                padding: 0;
                display: block;
                width: 100%;
                text-align: center;
                color: $primary;
                font-weight: 600;
                font-size: 1.6rem;
            }
            .blog-list{
                margin: 2vh 0 0 0;
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .blog-item{
                    margin: 0 0 2vh 0;
                    padding: 40px 30px;
                    display: block;
                    width: 90%;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    .blog-item-image{
                        width: 100%;
                        height: 60vw;
                        object-fit: cover;
                    }
                    .blog-item-text{
                        margin: 1vh 0 0 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        .blog-item-text-title{
                            margin: 0;
                            padding: 0;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            text-align: center;
                            color: $primary;
                            font-size: 1.1rem;
                            font-weight: 600;
                            -webkit-line-clamp: 3;
                            overflow-y: hidden;
                            text-overflow: ellipsis;
                            width: 100%;
                            height: 10vh;
                        }
                        .blog-item-text-link{
                            margin: 1vh auto 0 auto;
                            padding: 3px 0;
                            display: block;
                            width: 70%;
                            text-decoration: none;
                            text-align: center;
                            border: 2px solid $primary;
                            border-radius: 27px;
                            font-weight: 700;
                            cursor: pointer;
                            transition: .3s;
                            color: $primary;
                            &:hover{
                                background-color: $primary;
                                color: #FFF;
                            }
                        }
                    }
                }
            }
            .blog-see-more{
                margin: 5vh auto 0 auto;
                padding: 6px 0;
                display: block;
                width: 200px;
                text-align: center;
                background-color: $primary;
                color: #FFF;
                border-radius: 27px;
                text-decoration: none;
                
            }
        }
        #footer-container{
            #footer-logo{
                display: none;
            }
            #footer-content{
                margin: 0;
                padding: 5vh 0;
                width: 100%;
                flex-direction: column;
                text-align: center;
                .footer-col{
                    padding: 0;
                    margin: 2vh 0;
                    .footer-title,
                    .footer-link{
                        color: $text-white;
                        margin: 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        text-decoration: none;
                        cursor: pointer;
                        transition: .3s;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                    .footer-title{
                        font-weight: 500;
                        font-size: 1.1rem;
                    }
                    .footer-link{
                        font-size: 0.9rem;
                        font-weight: 300;
                        margin-bottom: 7px;
                    }	
                    #footer-social-media-list{
                        margin: 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        text-align: center;
                        list-style: none;
                        .footer-social-media-item{
                            margin: 0;
                            padding: 0 25% 0 0;
                            a{
                                margin: 0;
                                padding: 0;
                                display: block;
                                width: 100%;
                                i{
                                    color: $text-white;
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #integrations{
        background-color: #F2F2F2;
        padding: 6vh 0;
        width: 100%;
        #integrations-title{
            color: $primary;
            text-align: center;
            margin: 0;
            padding: 1vh 0 4vh 0;
            font-weight: 600;
            font-size: 1.6rem;
            letter-spacing: 0.6px;
        }
        #integrations-list{
            /*makes an horizontal carousel list*/
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            text-align: center;
            /*change style of horizontal scrollbar*/
            &::-webkit-scrollbar {
                height: 5px;
            }
            .integration-item{
                display: inline-block;
                margin: 0 1%;
                text-align: center;
                .integration-item-image{
                    width: auto;
                    height: 100px;
                    transition: .3s;
                    &:hover{
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    #our-clients{
        background-color: #FFF;
        padding: 6vh 0;
        width: 100%;
        #our-clients-title{
            color: $primary;
            text-align: center;
            margin: 0;
            padding: 2vh 0 4vh 0;
            font-weight: 600;
            font-size: 1.6rem;
            letter-spacing: 0.6px;
        }
        .our-clients-list{
            /*makes an horizontal carousel list*/
            margin: 2vh 0;
            padding: 0;
            display: block;
            width: 100%;
            overflow-x: hidden;
            overflow-y: hidden;
            text-align: center;
            /*change style of horizontal scrollbar*/
            &::-webkit-scrollbar {
                height: 1px;
            }
            .our-clients-item{
                display: inline-block;
                margin: 1vh 2%;
                text-align: center;
                width: 16vw;
                height: 16vw;
                border-radius: 50%;
                background-size: 100% auto; /* 100% width, auto height */
                background-repeat: no-repeat;
                background-position: center;
                transition: .3s;
                -webkit-box-shadow: 0px 5px 10px 0px rgba(204,204,204,1);
                -moz-box-shadow: 0px 5px 10px 0px rgba(204,204,204,1);
                box-shadow: 0px 5px 10px 0px rgba(204,204,204,1);
                /*set filter for background image bw*/
                filter: grayscale(100%);
                &:hover{
                    /*set filter for background image color*/
                    filter: grayscale(0%);
                    transform: scale(1.1);
                }
            }
        }
    }
    #testimonials{
        background-color: #F8F8F8;
        padding: 4vh 0 6vh 0;
        width: 100%;
        #testimonials-title{
            color: $primary;
            text-align: center;
            margin: 0;
            padding: 2vh 0 4vh 0;
            font-weight: 600;
            font-size: 1.6rem;
            letter-spacing: 0.6px;
        }
        .testimonials-container{
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;
            flex-direction: column;
            .testimonial-item{
                margin: 0 auto 4vh 0;
                .testimonial-item-image{
                    border: 3px solid #FFFFFF;
                    border-radius: 50%;
                    width: 100px;
                    margin-right: -50px;
                    z-index: 1;
                }
                .testimonial-item-text{
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 19px;
                    width: 100%;
                    padding: 3vh 20px 3vh 60px;
                    .testimonial-item-text-description{
                        margin: 0;
                        padding: 0;
                        display: block;
                        color: $text-black;
                        font-weight: 400;
                        font-size: .8rem;
                    }
                    .testimonial-item-text-author{
                        margin: 2vh 0 0 0;
                        padding: 0;
                        display: block;
                        color: $text-gray;
                        text-align: right;
                        font-weight: 300;
                        font-size: .8rem;
                    }
                    .testimonial-item-text-company{
                        margin: 0;
                        padding: 0;
                        display: block;
                        color: $text-gray;
                        text-align: right;
                        font-weight: 300;
                        font-size: .8rem;
                    }
                }
            }
        }
    }
    #success-stories{
        background-color: #F8F8F8;
        padding: 0 0 5vh 0;
        width: 100%;
        #success-stories-title{
            color: $primary;
            text-align: center;
            margin: 0;
            padding: 0 0 6vh 0;
            font-weight: 600;
            font-size: 1.6rem;
            letter-spacing: 0.6px;

        }
        #success-stories-list{
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;
            list-style: none;
            /*horizontal scrollable list*/
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            text-align: center;
            /*change style of horizontal scrollbar*/
            &::-webkit-scrollbar {
                height: 5px;
                background: #FFF;
    
            }
            &::-webkit-scrollbar-thumb{
                background: $text-gray;
            }
            &::-webkit-scrollbar-track{
                background: #F8F8F8;
            }
            .success-story-item{
                display: inline-block;
                margin: 0 5px;
                padding: 0 0 4vh 0;
                text-align: center;
                transition: .3s;
                width: 47vw;
                .success-story-item-image-container{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 47vw;
                    height: 60vw;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
    
                }
                .success-story-item-text-title{
                    margin: 1vh 0 0 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    color: $text-black;
                    font-size: 1.2em;
                    font-weight: 400;
                }
                .success-story-item-text-link{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    color: $text-link;
                    font-weight: 300;
                    font-size: .9rem;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden; /* Hide the overflow */
                }
            }
        }
    }
    #chat-container{
        #chat-button{
            margin: 0;
            padding: 0;
            display: block;
            width: 50px;
            height: 50px;
            cursor: pointer;
            position: fixed;
            bottom: 3%;
            right: 3%;
            background-color: transparent;
            border-radius: 50%;
            transition: .3s;
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            background-image: url('/images/chat.svg');
            background-size: contain; /* 100% width, auto height */
            background-repeat: no-repeat;
            background-position: center;
            &:hover{
                transform: scale(1.1);
            }
        }
        #chat-conversation-container{
            margin: 0;
            padding: 0;
            display: none;
            width: 85%;
            position: fixed;
            bottom: 0;
            right: 0;
            background-color: #FFF;
            z-index: 10;
            #chat-header{
                background: rgb(0,5,123);
                background: linear-gradient(180deg, rgba(0,5,123,1) 0%, rgba(1,83,255,1) 100%);
                color: $text-white;
                margin: 0;
                padding: 10px 3%;
                width: 100%;
                .fa-comment-dots{
                    font-size: 1.5em;
                    &:hover{
                        color: #fff !important;
                    }
                }
                #chat-title{
                    margin: 0 0 0 10px;
                    padding: 0;
                    font-size: 1.2em;
                }
                .fa-times{
                    &:hover{
                        color: #fff;
                    }
                }
            }
            #chat-body{
                margin: 0;
                padding: 0;
                display: block;
                width: 100%;
                #chat-messages-container{
                    height: 40vh;
                    margin: 0;
                    padding: 0 3%;
                    .chat-message-container{
                        padding: 0 3%;
                        display: block;
                        width: 100%;
                        height: auto; /* Ajusta automáticamente la altura según el contenido */
                        overflow: hidden; /* Oculta el contenido que sobrepase el ancho */
                        .chat-message-robot-img{
                            width: 25px;
                        }
                        .chat-message-robot-text{
                            padding: 10px 10px;
                            color: $text-black;
                            border-radius: 9px;
                            margin: 0;
                            width: 100%;
                            white-space: normal;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        &.robot{
                            margin: 10px auto 10px 0;
                            .chat-message-robot-text{
                                background-color: #E5EDFF;
                                margin-left: 10px;
                            }
                        }
                        &.user{
                            margin: 10px 0 10px auto;
                            .chat-message-robot-text{
                                background-color: #F0F0F0;
                            }
                        }
                    }
                }
                #chat-input-container{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    -webkit-box-shadow: 0px 0px 3px 0px rgba(153,153,153,1);
                    -moz-box-shadow: 0px 0px 3px 0px rgba(153,153,153,1);
                    box-shadow: 0px 0px 3px 0px rgba(153,153,153,1);
                    #chat-input{
                        width: inherit;
                        padding: 5px 0 0 3%;
                        height: 60px;
                        border: none;
                        &:focus, &:active  {
                            border-color: initial !important; /* Resets the border color to the default */
                            box-shadow: none !important; /* Removes the box shadow */
                            border: none !important;
                            outline: 0 !important;
                        }
                    }
                    #chat-send{
                        margin: 0 3%;
                        font-size: 15px;
                        
                    }
                }
            }
        }
    }
}
